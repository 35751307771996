.quote-box{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;  
    background-color: white;
}

.quote-text-box{
    max-width:600px;
    justify-content: center;
    align-items: center;
    flex-direction: row;  
    justify-content: center;
    position: relative;
    padding: 3em;
}

.quote-title{
    font-family: 'Noto Sans SC', Helvetica, arial, sans-serif;
    font-weight: 900;
    text-align: left;
    font-size: 3em;
}




.quote-img-container img{
    transition: transform .5s ease;
}

.quote-img-container:hover img{
    transform: scale(1.1); 
}


.text {
        text-align: left;
        width:100%;
        min-width:200px;
        max-width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: 'Noto Sans SC', Helvetica, arial, sans-serif;
        font-size: 1.3em;
        line-height: 1.5em;
     
    }

 