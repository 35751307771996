*{
  box-sizing:border-box;
}

html, body {
  overflow-x: hidden;
  width: 100vw;

}
body {
  width:100%;
}
